<template>
    <div style="text-align: left;">
        <el-row>
            <el-row></el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">最新活动</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                &nbsp;&nbsp;&nbsp;&nbsp;{{new Date().getMonth()+2}}月·神乐老师握手会·正在紧张筹备
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <br />
        <el-row>
            <el-row>&nbsp;</el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">史莱姆币白皮书</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                &nbsp;&nbsp;&nbsp;&nbsp;2019年06月13日，神乐老师发布史莱姆币白皮书。
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>&nbsp;</el-row>
        <el-row>
            <el-row>&nbsp;</el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">神乐基金会徐家汇研发中心招聘工作正式启动，游戏项目美术、程序、策划团队欢迎您加入！</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                &nbsp;&nbsp;&nbsp;&nbsp;大家好，神乐基金会是一家筹集善款供神乐老师任意消费的非盈利组织，基于对神乐老师的信仰，研发各种神乐老师的周边产品。本次招聘不收取信徒任何费用，要求专业技能优秀，对神乐老师信仰忠诚者优先聘用。
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>&nbsp;</el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                <el-card class="box-card" style="font-size:larger ;">
                    美术类
                    <el-row>
                        <el-col :span="24">
                            <el-collapse v-model="activeNames">
                                <el-collapse-item title="技术美术TA" name="1">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="游戏3D场景地图关卡编辑" name="2">
                                    <div>职位招聘中</div>
                                </el-collapse-item>
                                <el-collapse-item title="游戏3D角色设计师" name="3">
                                    <div>职位招聘中</div>
                                </el-collapse-item>
                                <el-collapse-item title="游戏原画设计师" name="4">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="色盲原画设计师" name="5">
                                    <div>已入职</div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-col>
                    </el-row>
                    程序类
                    <el-row>
                        <el-col :span="24">
                            <el-collapse v-model="activeNames" @change="handleChange">
                                <el-collapse-item title="服务器开发" name="1">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="客户端开发" name="2">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="全栈工程师" name="3">
                                    <div>职位招聘中</div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-col>
                    </el-row>
                    策划类
                    <el-row>
                        <el-col :span="24">
                            <el-collapse v-model="activeNames" @change="handleChange">
                                <el-collapse-item title="项目策划" name="1">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="数值策划" name="2">
                                    <div>已入职</div>
                                </el-collapse-item>
                                <el-collapse-item title="脑洞策划" name="3">
                                    <div>职位招聘中</div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-col>
                    </el-row>
                    <span style="font-size: xx-small;line-height:1.7">
                        {{new Date().getFullYear()}}年{{new Date().getMonth()+1}}月{{new Date().getDate()}}日更新</span>
                </el-card>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
    </div>
</template>
<script>
export default {}
</script>
<style>
</style>