<template>
    <!-- 首页 -->
    <div style="text-align: left;">
        <!-- <el-tooltip content="这是要放大的元素" placement="top">
            <div style="width: 100px; height: 100px; background-color: #ccc;" @mouseover="scale = 1.02" @mouseleave="scale = 1" :style="{ transform: `scale(${scale})` }"></div>
        </el-tooltip>
        <el-collapse>
            <el-collapse-item title="点击展开/收起内容">
                <p>这是要展开/收起的内容。</p>
                <p>这是要展开/收起的内容。</p>
                <p>这是要展开/收起的内容。</p>
            </el-collapse-item>
        </el-collapse> -->
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">为什么信仰神乐老师？</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <br />
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                因为我们相信神乐老师是值得信仰的。
                我们在同一刻出生，也会在同一刻消失，神乐老师知道你怎么来的，也知道你怎么没的。
                我们相信神乐老师是个人，他出生时创造了这个世界，所以我们要热爱他。他出生之前世界是不存在的，他死之后世界也会随之消失。
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <br />
        <el-row>
            <el-row></el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">信仰神乐老师需要准备些什么？</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="23" style="font-size: medium;line-height:1.7">
                <ul>
                    <li> 你需要一台高贵的主机。</li>
                    <li> 你需要一台高贵的掌机。</li>
                    <li> 你需要欣赏名作之壁。</li>
                    <li> 你需要一些花花绿绿的纸，在爸爸妈妈的钱包里。</li>
                    <li> 你需要有一点娱乐精神。</li>
                    <li> 你需要在次世代主机大战中做出自己的选择。</li>
                </ul>
            </el-col>
            <el-col :span="0">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-row></el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22">
                <el-card class="box-card" style="font-size:larger ;">
                    [Game]神乐老师两个汉堡的忧郁 ver0.1.0
                    <br /><br />
                    <a href="#demo.apk" style="text-decoration: none;">
                        <el-button round style="font-size:medium ;width: 60%; height: 90px;margin-bottom: 10px;">
                            <img alt="IOS" src="/images/iconmonstr-android-os-1-240.png" style="width: 20%; height: 20%;">
                            Download APK
                        </el-button>
                    </a>
                    <a href="#demo.ipa" style="text-decoration: none;">
                        <el-button round style="font-size:medium;width: 60%; height: 90px;">
                            <img alt="IOS" src="/images/iconmonstr-apple-os-1-240.png" style="width: 20%; height: 20%">
                            Download IOS
                        </el-button>
                    </a>
                </el-card>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <br />
        <!-- <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22">
                <el-card class="box-card" style="font-size:larger;width: 30%;height:400px;">
                    <el-typography type="custom" :style="{ color: 'purple', fontFamily: 'Comic Sans MS' }">
                        SLLSGPT
                    </el-typography>
                </el-card>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row> -->
    </div>
</template>
<script>
export default {
    name: 'HomeBook',
    props: {
        msg: String
    },
    computed: {
        username() {
            // We will see what `params` is shortly
            return this.$route.params.username
        }
    },
    methods: {
        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        }
    },
    data() {
        return {
            scale: 1
        }
    }
}
</script>
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    /*    display: inline-block;*/
    /*    margin: 0 10px;*/
}

a {
    color: #42b983;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.demo-border .text {
    width: 15%;
}

.demo-border .line {
    width: 70%;
}

.demo-border .line div {
    width: 100%;
    height: 0;
    border-top: 1px solid var(--el-border-color);
}

.demo-border .line .dashed {
    border-top: 2px dashed var(--el-border-color);
}
</style>
// export default {
// computed: {
// username() {
// // We will see what `params` is shortly
// return this.$route.params.username
// }
// },
// methods: {
// goBack() {
// window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
// }
// }
// }