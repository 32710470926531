// import {Vue,VueRouter } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
// import home from '../components/HelloWorld.vue'
import home from '../views/HomeBook.vue'
import classic from '../views/ClassicBook.vue'
import welfare from '../views/WelfareBook.vue'
// 0. If using a module system (e.g. via vue-cli), import Vue and VueRouter
// and then call `Vue.use(VueRouter)`.

// 1. Define route components.
// These can be imported from other files
// const TestHome = { template: '<div>foo</div>' }
// const TestUser = { template: '<div>bar</div>' }

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: '/', name: 'home', component: home },
  { path: '/classic', name: 'classic', component: classic },
  { path: '/welfare', name: 'welfare', component: welfare }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
// const router = new VueRouter({
//   routes // short for `routes: routes`
// })
const router = createRouter({
  history: createWebHistory(),
  routes
})

// 4. Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
// const app = new Vue({
//   router
// }).$mount('#app')

// // Now the app has started!

// export default app;

export default router