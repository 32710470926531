<template>
    <div id="app">
        <div style="margin-bottom: 40px;">
            <el-row>
                <el-col :span="7" style="font-weight: bold;">
                    <div style="margin-left: 10px;">
                        <el-text style="font-size: medium">教会</el-text>
                        <br />
                        <el-text style="font-size: large;letter-spacing:6px;">神乐老师</el-text>
                        <br />
                        <el-text style="font-size: small;">的高贵圣徒们</el-text>
                    </div>
                </el-col>
                <!-- <el-col :span="2">
            </el-col> -->
                <el-col :span="17" style="text-align:right;">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect" style="font-size: default ;margin-top: 20px;" align="right">
                        <el-col :span="8">
                            <router-link to="/" style="text-decoration:none;color:inherit;">
                                <el-menu-item index="0">阅读</el-menu-item>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link to="/classic" style="text-decoration:none;color:inherit;">
                                <el-menu-item index="1">经典</el-menu-item>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/welfare" style="text-decoration:none;color:inherit;">
                                <el-menu-item index="2">福利</el-menu-item>
                            </router-link>
                        </el-col>
                        <el-col :span="2">
                        </el-col>
                    </el-menu>
                    <el-row style="font-size: medium;">
                        <!--  <el-col :span="8"><router-link to="/" >阅读</router-link></el-col>
             <el-col :span="8"><router-link to="/classic">经典</router-link></el-col>
             <el-col :span="8"><router-link to="/welfare">福利</router-link></el-col> -->
                    </el-row>
                </el-col>
            </el-row>
            <!-- <el-card shadow="always" style="text-align:right;">
            </el-card> -->
        </div>
        <router-view></router-view>
        <!-- 页尾 -->
        <div style="margin-top: 10%;">
            &nbsp;
            <el-row>
                <el-col :span="1"></el-col>
                <el-col :span="20" style="font-size: xx-small">© 2019 THE CHURCH OF SLLS OF GAOGUI SAINTS.元基基元科技有限公司.</el-col>
                <el-col :span="3"></el-col>
            </el-row>
        </div>
        <!-- <router-link to="/">shouye</router-link>
     |
    <router-link to="/TestHome">TestHome</router-link>
     |
    <router-link to="/TestUser">TestUser</router-link> -->
        <!-- <router-view></router-view> -->
    </div>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <Home msg="Welcome to Your Vue.js App"/> -->
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Home from './views/Home.vue'

export default {
    name: 'App',
    components: {
        // HelloWorld
        // home
    }
}
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    text-align: center;
    font-size: 14px;


    /*白色：#FFFFFF
黑色：#000000
暗灰色：#242424
浅灰色：#EFEFF4
深灰色：#8E8E93
蓝色：#0A84FF
绿色：#30D158
紫色：#BF5AF2
橙色：#FF9F0A
粉红色：#FF2D55*/
}
</style>