<template>
    <div style="text-align: left;">
        <el-row>
            <el-row></el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">最新视频</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                <ul>
                    <li><a href="https://youtu.be/eE9qgbbaJsQ" target="_blank"> 怒评PS4开箱篇 HD高清复刻 </a></li>
                    <li>
                        <iframe width="192" height="108" src="//www.youtube.com/embed/eE9qgbbaJsQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </li>
                </ul>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-row></el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">最新书籍</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                <ul>
                    <li>《改变世界的十元钱》</li>
                    <li>《改变世界的同人祭》</li>
                </ul>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-row></el-row>
            <br>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: x-large">最新游戏</el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22" style="font-size: medium;line-height:1.7">
                <ul>
                    <li>&nbsp;&nbsp;动用教会资金正在紧张筹备</li>
                </ul>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
        </el-row>
    </div>
</template>
<script>
export default {}
</script>
<style>
ul {
    list-style-type: none;
    padding: 0;
}
</style>